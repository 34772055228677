<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 18 17.997">
        <path id="Path_2017" data-name="Path 2017" d="M-770.523,45.6a9.013,9.013,0,0,0-9.009,8.993,9.014,9.014,0,0,0,9,9,9.013,9.013,0,0,0,9-9A9.012,9.012,0,0,0-770.523,45.6Zm1.416,12.394a7.959,7.959,0,0,1-.824.334c0,.126,0,.287,0,.448a.6.6,0,0,1-.6.625.6.6,0,0,1-.6-.633c0-.155,0-.31,0-.443a8,8,0,0,1-.813-.327,1.732,1.732,0,0,1-.983-1.548.6.6,0,0,1,.57-.658.588.588,0,0,1,.627.6.624.624,0,0,0,.357.555,1.758,1.758,0,0,0,1.69,0,.761.761,0,0,0,.1-1.23,2.945,2.945,0,0,0-.734-.4c-.408-.169-.838-.289-1.243-.464a2.089,2.089,0,0,1-1.335-1.659A1.854,1.854,0,0,1-771.477,51c.107-.033.216-.058.347-.093,0-.171,0-.334,0-.5a.6.6,0,0,1,.6-.611.6.6,0,0,1,.595.618c0,.162,0,.324,0,.473.233.081.459.139.666.235a1.765,1.765,0,0,1,1.132,1.643.594.594,0,0,1-.562.628.591.591,0,0,1-.632-.576.661.661,0,0,0-.427-.607,1.794,1.794,0,0,0-1.542,0,.76.76,0,0,0-.149,1.3,3.634,3.634,0,0,0,.812.409c.339.141.7.237,1.036.38a2.174,2.174,0,0,1,1.466,2.119A1.723,1.723,0,0,1-769.107,57.994Z" transform="translate(779.532 -45.6)"/>
    </icon-base>
</template>
<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
    components: { iconBase },
    name: 'iconCurrency',
    props:{
    size: {
        type: Number,
        default: 20
    },
        color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
    }
}
</script>
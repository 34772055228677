<template>
    <div class="d-flex flex-column justify-content-center align-items-center">
        <ul class="color-dark-gray-200 m-0 size-14">
            <li class="d-flex align-items-center" :class="[active == option.value ? 'active-tap' :'', index > 0 ? 'pt-3' : '']" @click="$emit('scrollToComponent', ($event, option.value))" v-for="(option, index) in options" :key="option.value">
                <component :is="option.icon" :color="'var(--dark-gray-200)'" class="me-3" :size="option.size" :class="[option.icon === 'icon-addresses' ? 'center-icon' : '']"></component>
                {{$t(option.title)}}
            </li>
        </ul>
    </div>
</template>

<script>
// import IconPerson from '../../../../common/svg/iconPerson.vue'
// import IconAddresses from '../../../layout/assets/svg/iconAddresses.vue'
// import IconPassword from '../../../auth/assets/svg/iconPassword.vue'
import { mapGetters } from "vuex"
// import IconProfile from '../../assets/svg/iconProfile.vue'
// import IconCloseSession from '../../../../common/svg/iconCloseSession.vue'
import IconAlert from '../../assets/svg/iconAlert.vue'
import IconAppearance from '../../assets/svg/iconAppearance.vue'
import IconCurrency from '../../assets/svg/iconCurrency.vue'
import iconPerson from "../../../../common/svg/iconPerson.vue"
import IconInvoice from '../../assets/svg/iconInvoice.vue'
import IconLanguage from '../../assets/svg/iconLanguage.vue'
import IconSecurity from '../../assets/svg/iconSecurity.vue'
export default {
    name: 'MenuSettings',
    data() {
        return {
            options: [
                {
                    value: 'notifications',
                    title: 'account.general_notifications',
                    icon: 'icon-alert',
                    size: 17
                },
                {
                    value: 'language',
                    title: 'account.language',
                    icon: 'icon-language',
                    size: 17
                },
                {
                    value: 'currency',
                    title: 'account.currency',
                    icon: 'icon-currency',
                    size: 17
                },
                {
                    value: 'security',
                    title: 'account.security',
                    icon: 'icon-security',
                    size: 15
                },
                {
                    value: 'appearance',
                    title: 'account.appearance',
                    icon: 'icon-appearance',
                    size: 16
                },
                {
                    value: 'invoice',
                    title: 'account.invoice',
                    icon: 'icon-invoice',
                    size: 13
                },
                {
                    value: 'DeleteAccount',
                    title: 'account.delete_account',
                    icon: 'icon-person',
                    size: 15
                },
            ],
            selectedTap:''
        }
    },
    props:{
        active: {
            type: Array
        }
    },
    components:{
        IconAlert, IconInvoice, IconLanguage, IconCurrency, IconSecurity, IconAppearance, iconPerson
    },
    computed:{
        ...mapGetters({
            userData: "auth/userData",
            addressSelected: "account/addressSelected",
        }),
    },
    methods:{
        uploadPhoto(){
            // console.log("Hola");
        }
    }
}
</script>

<style scoped>
    .button-logout{
        color: var(--main-navy);
        border: 0;
        background: transparent;
    }
    .button-logout:hover{
        color: var(--blue-600);
    }
    .button-logout:hover > .icon-close-session::v-deep > #Path_1965{
        transform: translate(-344px, -90.5px);
        transition: var(--transition-1);
    }
    ul{
        list-style: none;
        padding: 0;
    }
    li:hover svg{
        fill: var(--gray-1001);
    }
    li:hover {
        color: var(--gray-1001);
    }
    .active-tap, .active-tap svg{
        color: var(--blue-600) !important;
        fill: var(--blue-600) !important;
    }
</style>
<template>
    <div class="row">
        <div class="col-md-6 d-flex align-items-center">
            <p class="color-dark-gray-200 mb-0">{{$t("account.decide_the_style_in_which_you_want_to_view_your_account")}}.</p>
        </div>
        <div class="col-md-6 d-flex align-items-center mt-2 mt-md-0">
            <div class="d-flex flex-column ms-md-5 ms-0"> 
                <main-switch disabled :id="'default-address'" description="account.dark_mode" :size="10" :value="darkMode" @changeValue="darkModeFunction"/>
            </div>
        </div>
    </div>
</template>

<script>
import MainSwitch from '../../../../common/components/switch/MainSwitch.vue'
export default {
    components: { MainSwitch },
    name: "Appearance",    
    data() {
        return {
            darkMode: false,
            theme: ''
        }
    },
    methods:{
        darkModeFunction(data){
            this.theme = data ? 'darkMode' : ''; //toggles theme value
            document.documentElement.setAttribute('data-theme', this.theme); // sets the data-theme attribute
            localStorage.setItem('theme', this.theme); // stores theme value on local storage
        }
    }
}
</script>
<style scoped>
    .main-switch::v-deep > span{
        color: var(--dark-gray-400);
        font-weight: 500;
    }
</style>
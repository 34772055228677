<template>
    <div class="row">
        <div class="col-md-6">
            <p class="color-dark-gray-200 mb-0">{{$t("account.select_where_you_want_your_bills_to_go")}}.</p>
        </div>
        <div class="col-md-6">
            <div class="d-flex flex-column ms-md-5 ms-0 mt-2 mt-md-0"> 
                <main-switch :id="'send-email'" description="account.send_to_my_email" :size="10" :value="sendMyEmail" @changeValue="(data) => {sendMyEmail = data}"/>
                <span class="subtitle-13 mt-1 color-dark-gray-200" style="margin-left: 57px;">{{user.email}}</span>
                <main-switch class="mt-3" id="send-alternate" description="account.send_to_alternate_email" :size="10" :value="sendAlternate" @changeValue="(data) => {sendAlternate = data}"/>
                <main-input class="mt-3" id="send-alternate-input" placeholder="Example@gmail.com" :disabled="!sendAlternate" style="margin-left: 48px;">
                    <icon-email color="var(--dark-gray-200)"/>
                </main-input>
            </div>
        </div>
    </div>
</template>

<script>
import MainSwitch from '../../../../common/components/switch/MainSwitch.vue'
import { mapGetters } from "vuex"
import MainInput from '../../../../common/components/inputs/MainInput.vue'
import IconEmail from '../../../auth/assets/svg/iconEmail.vue'

export default {
    components: { MainSwitch, MainInput, IconEmail },
    name: "Invoice",    
    data() {
        return {
            sendMyEmail: false,
            sendAlternate: false
        }
    },
    computed:{
        ...mapGetters({
            user:"auth/userData",
        }),
    },
}
</script>
<style scoped>
    .main-switch::v-deep > span{
        color: var(--dark-gray-400);
        font-weight: 500;
    }
</style>
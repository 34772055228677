<template>
    <div class="row">
        <div class="col-md-6 d-flex align-items-center">
            <p class="color-dark-gray-200 size-15 mb-0">{{$t("account.select_the_currency_of_your_preference")}}.</p>
        </div>
        <div class="col-md-6">
            <main-select :options="filterOptions" :selectedOption="selectedOption" summaryRef="select-currency" style="width: 200px" class="ms-md-5 ms-0 mt-1 mt-md-0"/>
        </div>
    </div>
</template>

<script>
import MainSelect from '../../../../common/components/selects/MainSelect.vue'
export default {
    components: { MainSelect },
    name:"Currency",
    data() {
        return {
            filterOptions: [
                {
                    value: 0,
                    title: 'DOP - Peso'
                },
                {
                    value: 1,
                    title: 'USD - Dollar'
                },
            ],
            selectedOption: {value:0, title: 'DOP - Peso'},
        }
    },
}
</script>
<template>
    <div class="d-flex flex-column justify-content-center py-2">
        <main-modal @closeModal="$emit('closeModal')" preventClose>
            <div class="d-flex flex-column justify-content-center justify-content-md-center mb-4 px-2">
                <h6 class="title-22 color-blue-700 mb-1">{{$t("(TOTP) time-based one-time password code")}}</h6>
                <qr-code :text="secretCode" :size="250" color="#000" bg-color="transparent" error-level="L" class="qr-code mt-3"/>
            </div>
            <form @submit.prevent="" autocomplete="off" class="mt-4">
                <main-input title="auth.mfa_totp" id="code" ref="code" class="mb-2" placeholder="auth.code_placeholder"
                @changeValue="(data)=>{code = data}" :mask="'###-###'">
                    <icon-code :size="15" color="var(--dark-gray-200)" />
                </main-input>
                <div class="d-flex justify-content-end">
                    <main-button :title="'auth.save'" @click="verify"/>
                </div>
            </form>
        </main-modal>
    </div>
    </template>
    
    <script>
    
    import MainModal from '../../../common/components/modals/MainModal.vue';
    import QrCode from 'vue-qrcode-component';
    import MainInput from '../../../common/components/inputs/MainInput.vue';
    import MainButton from '../../../common/components/buttons/MainButton.vue';
    import IconCode from '../assets/svg/iconCode.vue';
    import { Auth } from 'aws-amplify';
    import { mapGetters } from 'vuex';
    
    export default {
        components: {
            MainModal,
            MainInput,
            MainButton,
            IconCode,
            QrCode,
        },
        props:{
            invoiceReturnData: {
                type: Array
            },
        },
        data(){
            return {
                secretCode: '',
                code: '',
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/userData",
            }),
        },
        name: "GenerateTOTP",
        methods:{
            async getCode() {
                const user = await Auth.currentAuthenticatedUser();
                const code = await Auth.setupTOTP(user);
                const str = "otpauth://totp/AWSCognito:" + "?secret=" + code;
                return this.secretCode = str
            },
            async verify(){
                try {
                    this.$refs.code.$v.$touch();
                    if (this.$refs.code.$v.$invalid){
                        return
                    }
                    const code = this.code.replace(/\D/g, '');
                    const user = await Auth.currentAuthenticatedUser();
                    
                    await Auth.verifyTotpToken(user, code)
                    .then(() => {
                        // don't forget to set TOTP as the preferred MFA method
                        Auth.setPreferredMFA(user, 'TOTP');
                        const notify = {
                            text: 'auth.mfa_preference',
                            type: 'success',
                            show: true,
                        }
                        this.$store.commit("auth/SET_STATE_PREFERRED_MFA", true)
                        this.$emit("closeModal")
                        this.$store.commit("common/SET_SHORT_NOTIFY", notify)
                    })
                    .catch((e) => {
                        const errorData = {
                            show: true,
                            title: `Ocurrio un error obteniendo los datos.`,
                            contentHTML: 
                            `<div class="text-center color-dark-gray-300">
                                <span class="ms-1">${ e }</span>
                            </div>`
                        }
                        this.$store.commit('common/SHOW_MODAL_ERROR', errorData)
                        console.log(e);
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        },
        async created(){
            this.getCode()
        }
    }
    </script>
    
    <style scoped>
        /* Style */
        .qr-code::v-deep img{
            margin: 0 auto;
        }
    </style>
<template>
    <div class="row">
        <div class="col-md-6">
            <p class="color-dark-gray-200 size-15 mb-0">{{$t("account.description_notifications")}}.</p>
        </div>
        <div class="col-md-6">
            <div class="d-flex flex-column ms-md-5 ms-0 my-3 my-md-0"> 
                <main-switch id="change-password" description="account.change_of_password" :size="10" :value="changePassword" @changeValue="(data) => {changePassword = data}"/>
                <main-switch class="mt-3" :id="'newsletter'" description="account.newsletter" :size="10" :value="newsletter" @changeValue="(data) => {newsletter = data}"/>
                <main-switch class="mt-3" :id="'updates'" description="account.updates" :size="10" :value="updates" @changeValue="(data) => {updates = data}"/>
                <main-switch class="mt-3" :id="'offers'" description="account.offers" :size="10" :value="offers" @changeValue="(data) => {offers = data}"/>
                <main-switch class="mt-3" :id="'desktop-notifications'" description="account.desktop_notifications" :size="10" :value="desktop" @changeValue="(data) => {desktop = data}"/>
            </div>
        </div>
    </div>
</template>

<script>
import MainSwitch from '../../../../common/components/switch/MainSwitch.vue'
export default {
    components: { MainSwitch },
    name: "Notifications",
    data() {
        return {
            changePassword: false,
            newsletter: false,
            updates: false,
            offers: false,
            desktop: false
        }
    },
}
</script>
<style scoped>
    .main-switch::v-deep > span{
        color: var(--dark-gray-400);
        font-weight: 500;
    }
</style>
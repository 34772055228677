<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 18 13.5">
        <path id="Icon_awesome-image" data-name="Icon awesome-image" d="M16.313,18H1.687A1.687,1.687,0,0,1,0,16.313V6.188A1.687,1.687,0,0,1,1.687,4.5H16.313A1.687,1.687,0,0,1,18,6.188V16.313A1.687,1.687,0,0,1,16.313,18ZM3.937,6.469A1.969,1.969,0,1,0,5.906,8.438,1.969,1.969,0,0,0,3.937,6.469ZM2.25,15.75h13.5V11.813L12.673,8.736a.422.422,0,0,0-.6,0L7.312,13.5,5.361,11.548a.422.422,0,0,0-.6,0L2.25,14.063Z" transform="translate(0 -4.5)"/>
    </icon-base>
</template>
<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
    components: { iconBase },
    name: 'iconAppearance',
    props:{
    size: {
        type: Number,
        default: 20
    },
        color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
    }
}
</script>
<template>
  <div class="main-switch d-flex align-items-center">
    <label class="checker" :class="[{'checked': checked}]">
      <input v-model="checked" :id="id" :checked="isChecked" :disabled="disabled" class="checkbox" type="checkbox"/>
      <div class="checkmark">
        <svg viewBox="0 0 100 100">
          <path d="M20,55 L40,75 L77,27" fill="none" stroke="#FFF" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </label>
    <span v-if="description" class="title-14 ms-2 mb-0">{{$t(description)}}</span>
  </div>
</template>

<script>
export default {
    name: 'MainSwitch',
    props: {
      isChecked: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      id: {
        type: String,
        required: true
      },
      description: {
        type: String
      },
      value: {
        type: Boolean
      }
    },
    data() {
      return {
        checked: false
      }
    },
    watch:{
      value(val){
        this.checked = val
      },
      checked(val){
        this.$emit("changeValue", val)
      }
    },
    created(){
      this.checked = this.value
    }
}
</script>

<style scoped>
.checker {
  display: block;
  height: 16px;
  width: 36px;
  box-sizing: content-box;
  padding: 2px 3px;
  border-radius: 1.25em;
  transition: transform .4s ease;
  cursor: pointer;
  background-color: #DFE6ED;
  user-select: none;
}

.checkmark {
  width: 1em;
  height: 1em;
  transition: transform .4s ease;
  transform: translateX(-0.4em);
  z-index: 5;
}
.checkmark svg {
  display: block;
  background: #ffffff;
  transform: translateX(0.4em);
  border-radius: 1em;
  transition: background-color .4s ease, transform calc(.4s * 1.5) ease;
  width: 14px;
  height: 14px;
  margin-top: 1px;
}
.checkmark svg path {
  stroke-dasharray: 90 90;
  stroke-dashoffset: 90;
  transition: stroke-dashoffset calc(.4s / 3) linear calc(.4s / 3);
}
.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.4;
  visibility: hidden;
}
.checkbox:checked ~ .checkmark {
  transform: translate(1.8em);
}
.checkbox:checked ~ .checkmark svg {
  background: #ffffff;
  transform: translate(-0.4em);
}
.checked {
  background: var(--blue-600) !important;
}
.checkbox:checked ~ .checkmark svg path {
  stroke-dashoffset: 0;
}
.checker.checker:active {
  transform: scale(0.85);
  transition-duration: calc(.4s / 2);
}
.checker.checker:active .check-bg::before {
  transform: translateY(0) scale(0.8);
  opacity: 0.2;
  
}
*,
*::before,
*::after {
  box-sizing: border-box;
  position: relative;
}
</style>>

<template>
    <div class="row">
        <div class="col-md-6 d-flex align-items-center">
            <p class="color-dark-gray-200 size-15 mb-0">{{$t("account.select_the_language_of_your_preference")}}.</p>
        </div>
        <div class="col-md-6">
            <main-select :options="filterOptions" :selectedOption="selectedOption" @clickHandler="changeLanguage" summaryRef="select-language" style="width: 200px" class="ms-0 ms-md-5 mt-1 mt-md-0"/>
        </div>
    </div>
</template>

<script>
import MainSelect from '../../../../common/components/selects/MainSelect.vue'
export default {
    components: { MainSelect },
    name:"Language",
    data() {
        return {
            filterOptions: [
                {
                    value: 'es',
                    title: 'common.language.ES',
                },
                {
                    value: 'en',
                    title: 'common.language.EN',
                },
            ],
            selectedOption: {value:'es', title: 'common.language.ES'},
        }
    },
    methods:{
        async changeLanguage(lang){
            this.selectedOption.value = lang.value
            this.$i18n.locale = lang.value
            localStorage.setItem("lang", lang.value)
        }
    },
    created(){
        this.selectedOption.value = localStorage.getItem("lang") || 'es'
    }
}
</script>
<template>
    <svg :width="size + 'px'" :fill="color"  viewBox="0 0 84.482 100">
        <path id="Security_icon" data-name="Security icon" d="M89.324,15.935Q70.632,8.2,51.943.41a4.759,4.759,0,0,0-3.866,0Q29.389,8.209,10.7,15.943A4.047,4.047,0,0,0,7.785,20.29V38.455A65.614,65.614,0,0,0,49.226,100h1.568l1.161-.49A65.415,65.415,0,0,0,92.231,38.839c0-6.18-.026-12.373,0-18.558A4.274,4.274,0,0,0,89.324,15.935ZM70.936,41.674a4.71,4.71,0,0,1-.545.549h0Q59.471,53.169,48.529,64.084a4.506,4.506,0,0,1-6.336.621,4.282,4.282,0,0,1-.638-.646Q35.489,58,29.439,51.939A4.486,4.486,0,0,1,35.7,45.508c.09.087.175.178.257.272,2.708,2.725,5.441,5.432,8.149,8.165a9.069,9.069,0,0,1,.794,1.133c4.009-4.022,7.727-7.748,11.444-11.47q3.833-3.848,7.68-7.684a4.5,4.5,0,1,1,6.915,5.758Z" transform="translate(-7.759 0)" fill="#a5b2b7"/>
    </svg>
</template>

<script>
export default {
    name: 'iconSecurity',
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>
</style>



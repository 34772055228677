<template>
  <section class="row">
    <div class="hover-blue cursor-pointer col-md-12 d-flex align-items-start align-items-md-center mb-5 px-2"  @click="goBack">
        <div class="me-3 mt-2 mt-md-0">
            <icon-arrow :size="25" style="transform: rotate(180deg);" :color="'var(--dark-gray-200)'"/>
        </div>
      <div class="d-flex flex-column align-items-start ms-1">
          <h6 class="title-22 color-blue-700 mb-1">{{$t("account.my_settings")}}</h6>
          <h6 class="subtitle-16 mb-0">{{$t("account.description_my_settings")}}</h6>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 justify-content-center">
        <card class="card-settings mb-5 me-4 ms-5">
            <menu-settings @logout="logoutModal = true" :active="active" @scrollToComponent="scrollToComponents"/>
        </card> 
    </div>
    <div class="'col-md-12  col-lg-8">
      <div class="d-flex flex-column align-items-start">
          <h6 class="title-22 mb-3">{{$t("account.preferences")}}</h6>
          <h6 class="subtitle-16 mb-3">{{$t("account.description_preferences")}}</h6>
      </div>
      <!-- <template > -->
        <div v-for="(section, i) in menuTree" :key="section.key" class="mb-4 mb-md-0">
          <scroll-view :offset="100">
            <template slot-scope="visibleSection">
              <scroll-marker :key="section.key" :visible="visibleSection[section.key]" :spacing="0" :name="section.key" @isVisible="sectionVisible" 
              @isNotVisible="key => sectionNotVisible(key, i)"></scroll-marker>
            </template>
          </scroll-view>
          <h4 class="title-14 mb-0 text-nowrap d-flex align-items-center"><component :is='section.icon' :size="section.size" class="me-2" color="var(--gray-1001)"></component> {{$t(section.name)}} <hr class="w-100 ms-3 bg-gray-100" style="height: 2px;"> </h4>
          <component :is='section.component' class="ms-2 my-2 my-md-4"></component>
        </div>
      <!-- </template> -->
    </div>
  </section>
</template>

<script>
import { $scrollview } from 'vue-scrollview'
import Card from '../../../common/components/cards/Card.vue'
import IconArrow from '../../../common/svg/iconArrow.vue'
import IconAlert from '../assets/svg/iconAlert.vue'
import IconAppearance from '../assets/svg/iconAppearance.vue'
import IconCurrency from '../assets/svg/iconCurrency.vue'
import IconInvoice from '../assets/svg/iconInvoice.vue'
import IconLanguage from '../assets/svg/iconLanguage.vue'
import IconSecurity from '../assets/svg/iconSecurity.vue'
import Appearance from '../components/settings/Appearance.vue'
import Currency from '../components/settings/Currency.vue'
import Invoice from '../components/settings/Invoice.vue'
import Language from '../components/settings/Language.vue'
import MenuSettings from '../components/settings/MenuSettings.vue'
import Notifications from '../components/settings/Notifications.vue'
import iconPerson from '../../../common/svg/iconPerson.vue'
import Security from '../components/settings/Security.vue'
import DeleteAccount from '../components/settings/DeleteAccount.vue'
export default {
  components: { Card, MenuSettings, IconArrow, Notifications, Language, Currency, Security, Appearance, Invoice, IconAlert, IconInvoice, IconLanguage, IconCurrency, IconSecurity, IconAppearance, DeleteAccount, iconPerson },
    data() {
        return {
            menuTree: [
              { name: 'account.general_notifications', key: 'notifications', component: 'notifications', icon: "icon-alert" },
              { name: 'account.language', key: 'language', component: "language", icon: "icon-language" },
              { name: 'account.currency', key: 'currency', component: 'currency', icon: "icon-currency" },
              { name: 'account.security', key: 'security', component: 'security', icon: "icon-security" },
              { name: 'account.appearance', key: 'appearance', component:"appearance", icon: "icon-appearance" },
              { name: 'account.invoice', key: 'invoice', component: 'invoice', icon: "icon-invoice", size: 15 },
              { name: 'account.delete_account', key: 'DeleteAccount', component: 'DeleteAccount', icon: "icon-person" }
            ],
            active: []
        }
    },
    methods:{
      sectionVisible(key){
        this.active = [key]
      },
      scrollToComponents(key) {
          $scrollview.scrollToComponent(key, 200);
          this.active = [key];
      },
      sectionNotVisible() {
        // console.log(key);
      },
      goBack(){
          this.$router.go(-1)
      },
    }
}
</script>

<style>
.card-settings{
  max-width: 285px;
  max-height: 303px;
  position: sticky !important;
  top: 10%;
  margin-bottom: 15px;
}
.hover-blue:hover svg{
  fill: var(--blue-600);
}
.hover-blue svg{
  transition: var(--transition-1);
}
</style>

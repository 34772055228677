<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 18 21.549">
        <path id="Path_2018" data-name="Path 2018" d="M986.117,173.044s-2.6,2.952-9,3.294v8.529a7.428,7.428,0,0,0,2.755,5.823,24.559,24.559,0,0,0,6.245,3.9,24.57,24.57,0,0,0,6.246-3.9,7.427,7.427,0,0,0,2.754-5.823v-8.529C988.714,176,986.117,173.044,986.117,173.044Zm2.161,14.982h-4.321l1.022-4.287a2.222,2.222,0,1,1,2.277,0Z" transform="translate(-977.117 -173.044)"/>
    </icon-base>
</template>
<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
    components: { iconBase },
    name: 'iconSecurity',
    props:{
    size: {
        type: Number,
        default: 20
    },
        color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
    }
}
</script>
<template>
    <div class="row">
        <div class="col-md-6 d-flex align-items-center">
            <p class="color-dark-gray-200 size-15 mb-0">{{ $t('account.delete_account_users') }}</p>
        </div>
        <div class="col-md-6 mt-2 mt-md-0">
            <div class="d-flex flex-column ms-md-5 ms-0">
                <div>
                    <second-button :disabled="entityValidation" :title="'Delete account'" class="me-3" @click="showSurely = true" :icon="true">
                        <icon-alert :size="12" class="me-2"/>
                    </second-button>
                </div>
            </div>
        </div>
        <confirm-delete-account v-if="showDelete" @closeModal="showDelete = false;" @confirmDelete="deleteCustomer"></confirm-delete-account>
        <surely-delete-account-vue v-if="showSurely" @closeModal="showSurely = false;" @confirm="openModalShowDelete"></surely-delete-account-vue>
    </div>
</template>

<script>
import ConfirmDeleteAccount from '../../../cart/components/modals/ConfirmDeleteAccount.vue';
import SecondButton from '../../../../common/components/buttons/SecondButton.vue';
import iconAlert from '../../assets/svg/iconAlert.vue';
import { mapGetters } from 'vuex';
import SurelyDeleteAccountVue from '../../../cart/components/modals/SurelyDeleteAccount.vue';
import { DisabledCUstomerService } from '../../services/DisabledCustomer-service';
const service = new DisabledCUstomerService();
export default {
    components: {
        SecondButton,
        iconAlert,
        ConfirmDeleteAccount,
        SurelyDeleteAccountVue 
    },
    name: "DelenteAccount",    
    computed: {
        ...mapGetters({
            changedQuotes: "account/changedQuotes",
            employee: "auth/employee"
        }),
        entityValidation(){
            return this.employee?.entity?.activated_employee
        },
    },
    data() {
        return {
            showSurely: false,
            showDelete: false,
            logoutCustomer: false,
        }
    },
    methods:{
        openModalShowDelete(){
            this.showSurely =  false,
            this.showDelete = true
        },
        async deleteCustomer() {
            try {
                const postUsers = await service.postDisabledCustomer()
                return postUsers
            } catch (error) {
                console.error(error)
            }finally{
                await this.$store.dispatch("auth/logout");
                this.logoutCustomer = true;
                this.showDelete = false;
            }
        }
    }
}
</script>

<style scoped>
.second-button{
    color: var(--dark-main-navy);
    border: 0.124rem solid;
    border-color: var(--dark-main-navy);
    border-radius: 5px;
    padding: 0 15px;
    font-weight: 400;
    line-height: 28px;
    transition: var(--transition-1);
    font-size: 14px;
}
</style>
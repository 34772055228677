import { http } from "../../../common/services/web-backend";

export class DisabledCUstomerService {
    async postDisabledCustomer(){
        try {
            const postUsers = await http.delete('/users');
            return postUsers
        } catch (error) {
            throw error.postUsers
        }
    }
}
<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 10.153 12.038">
        <path id="Path_706" data-name="Path 706" d="M1286.01,2287.487a1.45,1.45,0,0,1-2.9,0l0-.021q1.446.029,2.894,0Z" transform="translate(-1279.477 -2276.888)"/>
        <path id="Path_707" data-name="Path 707" d="M1289.495,2286.227a2.625,2.625,0,0,1-1.318-2.388,22.044,22.044,0,0,0-.041-2.438,3.613,3.613,0,0,0-2.487-2.884l-.01-.029c0-.159.007-.317.007-.475a1.083,1.083,0,0,0-2.137-.289,2.751,2.751,0,0,0-.028.764l-.01.029a3.6,3.6,0,0,0-2.528,3.288c-.013.73.009,1.461-.008,2.192a2.473,2.473,0,0,1-1.124,2.1.567.567,0,0,0-.321.631.111.111,0,0,1,0,.045c-.034.2.043.263.253.262,1.123-.008,6.267,0,6.267,0q1.558,0,3.118-.009c.5,0,.5,0,.5-.506A.284.284,0,0,0,1289.495,2286.227Z" transform="translate(-1279.477 -2276.888)"/>
    </icon-base>
</template>
<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
    components: { iconBase },
    name: 'iconAlert',
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>
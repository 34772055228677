<template>
    <main-confirm @confirm="$emit('confirmDisabled')" @cancel="$emit('closeModal')" confirm-title="auth.mfa_disabled" 
    description="auth.mfa_info" :buttonConfirmTitle="''">
        <icon-security :size="100" />
    </main-confirm>
</template>

<script>
import { mapGetters } from 'vuex';
import MainConfirm from '../../../../common/components/modals/MainConfirm.vue';
import iconSecurity from '../../../account/assets/svg/iconSecurity.vue';

export default {
    name: "ConfirmDisabledMFA",
    components: {
        MainConfirm,
        iconSecurity
    },
    props:{
        titleButton:{
            type:String,
            default: 'auth.confirm'
        }
    },
    computed:{
        ...mapGetters({
            user: "auth/userData"
        })
    },
    data() {
        return {
        }
    },
}
</script>
<template>
    <div class="row">
        <div class="col-md-6 d-flex align-items-center">
            <p class="color-dark-gray-200 size-15 mb-0">{{$t("account.protect_your_account_with_extra_layers_of_security")}}.</p>
        </div>
        <div class="col-md-6 mt-2 mt-md-0">
            <div class="d-flex flex-column ms-md-5 ms-0"> 
                <main-switch :id="'step-verification'" description="account.step_verification" :size="10" :value="stepVerification" @changeValue="(data) => {stepVerification = data}"/>
                <main-switch class="mt-3" :id="'unknown'" description="account.unknown_device_alert" :size="10" :value="unknown" @changeValue="(data) => {unknown = data}"/>         
            </div>
            <second-button  v-if="!entityValidation" :title="stateMFA ? 'auth.mfa_disabled' : 'Generate QR-TOTP'" class="mt-3 ms-md-5 ms-0" @click="setOTP" icon>
                <icon-security :size="12" class="me-2"/>
            </second-button>
        </div>
        <confirm-disabled v-if="confirmDisabledMFA" @closeModal="confirmDisabledMFA = false" @confirmDisabled="disabledMFA"/>
        <generateTOTP v-if="showGenerateTOTPModal" @closeModal="showGenerateTOTPModal = false"/>
    </div>
</template>

<script>
import ConfirmDisabled from '../../../cart/components/modals/ConfirmDisabledMFA.vue';
import MainSwitch from '../../../../common/components/switch/MainSwitch.vue'
import generateTOTP from '../../../auth/components/generateTOTP.vue';
import SecondButton from '../../../../common/components/buttons/SecondButton.vue';
import IconSecurity from '../../../home/assets/svg/policies/iconSecurity.vue';
import { mapGetters } from 'vuex';
import { Auth } from 'aws-amplify';
export default {
    components: { MainSwitch, generateTOTP, SecondButton, IconSecurity, ConfirmDisabled},
    name: "Security",
    computed: {
        ...mapGetters({
            employee: "auth/employee",
            getPreferred: "auth/getPreferred"
        }),
        entityValidation(){
            return this.employee?.entity?.activated_employee
        },
    },
    data() {
        return {
            unknown: false,
            stepVerification: false,
            showGenerateTOTPModal: false,
            confirmDisabledMFA: false,
            stateMFA: false,
        }
    },
    watch:{
        'showGenerateTOTPModal'(){
            this.stateMFA = this.getPreferred
        },
    },
    methods: {
        async disabledMFA(){
            const user = await Auth.currentAuthenticatedUser();
            await Auth.setPreferredMFA(user, 'NOMFA')
            .then(() => {
                this.stateMFA = false
                const notify = {
                    text: 'Preferencia guardada con exito',
                    type: 'success',
                    show: true,
                }
                this.confirmDisabledMFA = false
                this.$store.commit("common/SET_SHORT_NOTIFY", notify)
            })
            .catch((e) => {
                console.log(e);
            });
        },
        setOTP(){
            if(!this.stateMFA){
                this.showGenerateTOTPModal = true;
                return;
            }
            this.confirmDisabledMFA = true;
        }
    },
    async created(){
        const user = await Auth.currentAuthenticatedUser();
        await Auth.getPreferredMFA(user, {
            // Optional, by default is false.
            // If set to true, it will get the MFA type from server side instead of from local cache.
            bypassCache: false
        }).then((data) => {
            this.stateMFA = data == 'SOFTWARE_TOKEN_MFA';
        });
    }
}
</script>

<style scoped>

    .position-button{
        margin-top: 15px;
        margin-left: 48px;
    }
    .main-switch::v-deep > span{
        color: var(--dark-gray-400);
        font-weight: 500;
    }
</style>
<template>
    <main-confirm @confirm="$emit('confirm')" @cancel="$emit('closeModal')" confirm-title="account.delete_account"
    description="account.delete_account_inf">
        <!-- <icon-delete :size="100"/> -->
        <iconPerson :size="100" />
    </main-confirm>
</template>

<script>
import MainConfirm from '../../../../common/components/modals/MainConfirm.vue';
// import IconDelete from '../../assets/svg/iconDelete.vue';
import iconPerson from '../../../../common/svg/iconPerson.vue';

export default {
    name: "SurelyDeleteAccount",
    components: {
        MainConfirm,
        // IconDelete,
        iconPerson
    },
    props:{
        titleButton:{
            type:String,
            default: 'auth.confirm'
        }
    },
}
</script>
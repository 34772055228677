<template>
    <main-confirm @confirm="$emit('confirmDelete')" @cancel="$emit('closeModal')" confirm-title="account.delete_account" 
    description="account.delete_account_msg" :buttonConfirmTitle="''" :secondTitle="`${$t('account.miss_you_delete_account')} ${user ? user.entity.first_name : ''} ${user ? user.entity.last_name : ''} :(`">
        <!-- <icon-delete :size="100"/> -->
        <iconPerson :size="100" />
    </main-confirm>
</template>

<script>
import { mapGetters } from 'vuex';
import MainConfirm from '../../../../common/components/modals/MainConfirm.vue';
import iconPerson from '../../../../common/svg/iconPerson.vue';

export default {
    name: "ConfirmDeleteSelection",
    components: {
        MainConfirm,
        iconPerson
    },
    props:{
        titleButton:{
            type:String,
            default: 'auth.confirm'
        }
    },
    computed:{
        ...mapGetters({
            user: "auth/userData"
        })
    },
    data() {
        return {
        }
    },
}
</script>